<template>
  <v-row>
    <v-col v-if="!schema.components">
      <ArchetypeInput
        :path="schema.archetypeID"
        :schema="schema"
        :collections="dataPoints"
        @input="input"
      />
    </v-col>
    <v-col v-else>
      <v-row class="pa-0 ma-0">
        <v-col
          v-for="component in schema.components"
          :key="component.archetypeID"
        >
          <ArchetypeInput
            :path="schema.archetypeID + '/' + component.archetypeID"
            :schema="component"
            :collections="dataPoints"
            @input="input"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ArchetypeInput from "@/components/examination/ArchetypeInput.vue";
export default {
  components: {
    ArchetypeInput,
  },
  props: {
    schema: {
      type: Array,
      default: function () {
        return {};
      },
    },
    dataPoints: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    input(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style>
</style>