<template>
  <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover>
    <template v-slot:prev="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">Back</v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn color="info" v-bind="attrs" v-on="on">Next</v-btn>
    </template>
    <v-carousel-item v-for="(page, i) in template.pages" :key="'page-' + i">
      <v-sheet height="100%">
        <div class="text-h3">
          {{ page.title }}
        </div>
        <hr />
        <v-row class="fill-height" align="center" justify="center">
          <div fluid class="examination-form">
            <div fluid class="ma-0 pa-0 mt-2">
              <archetype-composite
                v-for="component in page.components"
                :key="page.title + component.name"
                :schema="component"
                :dataPoints="dataPoints"
              />
            </div>
          </div>
          <v-btn @click="getDataPoints">Get Data</v-btn>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import ArchetypeComposite from "./ArchetypeComposite.vue";

export default {
  components: {
    ArchetypeComposite,
  },
  data() {
    return {
      dataPoints: [],
      template: {},
    };
  },
  created() {
    this.renderTemplateLayout({
      name: "firstTemplate",
      title: "Hello World",
      description: "",
      pages: [
        {
          title: "Tell us a little about your self",
          description: "",
          components: [
            {
              archetypeID: "PERSONAL_INFO",
              name: "Personal Information",
              components: [
                {
                  archetypeID: "FULL_NAME",
                  name: "Fullname",
                  type: "text-field",
                  default: "",
                  required: true,
                  validator(value) {
                    return !!value;
                  },
                },
                {
                  archetypeID: "GENDER",
                  name: "Gender",
                  type: "radio",
                  options: ["Female", "Male", "Others"],
                  default: "Female",
                },
                {
                  archetypeID: "DOB",
                  name: "Birthdate",
                  type: "text-field",
                  mask: "DD-MM-YYYY",
                  default: "",
                },
                {
                  archetypeID: "PHONE",
                  name: "Phone",
                  type: "text-field",
                  mask: "(dd)-ddd-ddd-ddd",
                  default: "",
                },
              ],
            },
            {
              archetypeID: "ABOUT_ME",
              name: "About Me",
              type: "textarea",
              default: "",
            },
          ],
        },
        {
          title: "General Medical Information",
          description: "",
          components: [
            {
              archetypeID: "VITAL_SIGNS",
              name: "Vital Signs",
              components: [
                {
                  archetypeID: "HEIGHT",
                  name: "Height",
                  type: "text-field",
                  default: "",
                },
                {
                  archetypeID: "WEIGHT",
                  name: "Weight",
                  type: "text-field",
                  default: "",
                },
                {
                  archetypeID: "BLOOD_TYPE",
                  name: "Blood Type",
                  type: "select",
                  options: ["A", "B", "AB", "O"],
                  default: "",
                },
              ],
            },
          ],
        },
      ],
    });
    this.loadDataPoints([
      {
        archetypeID: "GENDER",
        path: "PERSONAL_INFO/GENDER",
        value: "Male|Female;Male;Others",
      },
      {
        archetypeID: "FULL_NAME",
        path: "PERSONAL_INFO/FULL_NAME",
        value: "Nguyen",
      },
      { archetypeID: "HEIGHT", path: "VITAL_SIGNS/HEIGHT", value: "165" },
      { archetypeID: "PHONE", path: "PERSONAL_INFO/PHONE", value: "123123123" },
      {
        archetypeID: "BLOOD_TYPE",
        path: "VITAL_SIGNS/BLOOD_TYPE",
        value: "A|A;B;AB;O",
      },
    ]);
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    // render from component layout
    async renderTemplateLayout(template) {
      if (!template) return;
      this.template = template;
    },
    // render data points
    loadDataPoints(dataPoints = []) {
      this.dataPoints = dataPoints;
    },
    getDataPoints() {
      console.log(JSON.stringify(this.dataPoints));
    },
  },
};
</script>